import {mapActions, mapGetters} from "vuex";
import product from '@/components/product/index.vue';

export default {
    name: "favorites",
    components: {
        product
    },
    data() {
        return {}
    },
    created() {
        this.fetchFavoriteList().then(() => {
            // console.log(this.whichList);
        })
    },
    computed: {
        ...mapGetters({
            whichList: 'favorites/whichList'
        }),
    },
    methods: {
        ...mapActions({
            fetchFavoriteList: `favorites/GET_FAVORITES_LIST`
        })
    }

};
